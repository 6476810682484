import { createRouteRef } from '@backstage/core-plugin-api';

export const rootRouteRef = createRouteRef({
  id: 'dcm',
});

export const entityRouteRef = createRouteRef({
  id: 'dcm/entity',
  params: ["path"]
});

export const generateRouteRef = createRouteRef({
  id: 'dcm/generate/template',
  params: ["path"]
});
