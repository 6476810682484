import {
  createBaseThemeOptions,
  createUnifiedTheme,
  genPageTheme,
  palettes,
  shapes,
} from '@backstage/theme';

const pageTheme = genPageTheme({ colors: ['#001177', '#02a2fd'], shape: shapes.wave });

export const BlueAllyLightTheme = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: {
      ...palettes.light,
      //primary: {
      //  main: '#343b58',
      //},
      //secondary: {
      //  main: '#565a6e',
      //},
      //error: {
      //  main: '#8c4351',
      //},
      //warning: {
      //  main: '#8f5e15',
      //},
      //info: {
      //  main: '#34548a',
      //},
      //success: {
      //  main: '#485e30',
      //},
      //background: {
      //  default: '#d5d6db',
      //  paper: '#d5d6db',
      //},
      //banner: {
      //  info: '#34548a',
      //  error: '#8c4351',
      //  text: '#343b58',
      //  link: '#565a6e',
      //},
      //errorBackground: '#8c4351',
      //warningBackground: '#8f5e15',
      //infoBackground: '#343b58',
      navigation: {
        background: '#0e0e0e',
        indicator: '#154c8f',
        color: '#d5d6db',
        selectedColor: '#ffffff',
      },
    },
  }),
  //defaultPageTheme: 'home',
  //fontFamily: 'Comic Sans MS',
  ///* below drives the header colors */
  pageTheme: {
    home: pageTheme,
    documentation: pageTheme,
    tool: pageTheme,
    service: pageTheme,
    website: pageTheme,
    library: pageTheme,
    other: pageTheme,
    app: pageTheme,
    apis: pageTheme,
  },
});

export const BlueAllyDarkTheme = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: {
      ...palettes.dark,
      //primary: {
      //  main: '#343b58',
      //},
      //secondary: {
      //  main: '#565a6e',
      //},
      //error: {
      //  main: '#8c4351',
      //},
      //warning: {
      //  main: '#8f5e15',
      //},
      //info: {
      //  main: '#34548a',
      //},
      //success: {
      //  main: '#485e30',
      //},
      //background: {
      //  default: '#1a1a1a',
      //  paper: '#0a0a0a',
      //},
      //banner: {
      //  info: '#34548a',
      //  error: '#8c4351',
      //  text: '#343b58',
      //  link: '#565a6e',
      //},
      //errorBackground: '#8c4351',
      //warningBackground: '#8f5e15',
      //infoBackground: '#343b58',
      navigation: {
        background: '#0e0e0e',
        indicator: '#154c8f',
        color: '#d5d6db',
        selectedColor: '#ffffff',
      },
    },
  }),
  //defaultPageTheme: 'home',
  fontFamily: 'Cairo',
  ///* below drives the header colors */
  pageTheme: {
    home: pageTheme,
    documentation: pageTheme,
    tool: pageTheme,
    service: pageTheme,
    website: pageTheme,
    library: pageTheme,
    other: pageTheme,
    app: pageTheme,
    apis: pageTheme,
  },
});
