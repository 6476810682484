import React from 'react';

import {
  Header,
  Page,
  Content,
} from '@backstage/core-components';

export const HomeIndexPage = () => {
  return (
    <Page themeId="home">
      <Header title="Home"/>

      <Content>TODO</Content>
    </Page>
  )
};

