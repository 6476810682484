import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef, entityRouteRef, generateRouteRef } from './routes';

export const dcmPlugin = createPlugin({
  id: 'dcm',
  routes: {
    root: rootRouteRef,
  },
});

export const DcmPage = dcmPlugin.provide(
  createRoutableExtension({
    name: 'DcmPage',
    component: () => import('./components/Catalog').then(m => m.Catalog),
    mountPoint: rootRouteRef,
  }),
);

export const DcmEntityPage = dcmPlugin.provide(
  createRoutableExtension({
    name: 'DcmEntityPage',
    component: () => import('./components/Entity').then(m => m.Entity),
    mountPoint: entityRouteRef,
  }),
);

export const DcmGeneratePage = dcmPlugin.provide(
  createRoutableExtension({
    name: 'DcmGeneratePage',
    component: () => import('./components/Generate').then(m => m.Generate),
    mountPoint: generateRouteRef,
  }),
);
